var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, TextField, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import emailjs from '@emailjs/browser';
import { EmailConfig } from '../../../email.config';
var ContactForm = function () {
    var _a, _b, _c;
    var _d = useState({
        formSubmitted: false,
    }), componentState = _d[0], setComponentState = _d[1];
    var updateComponentState = function (newComponentState) {
        setComponentState(function (componentState) {
            return __assign(__assign({}, componentState), newComponentState);
        });
    };
    var _e = useForm(), register = _e.register, handleSubmit = _e.handleSubmit, control = _e.control, clearErrors = _e.clearErrors, setError = _e.setError, setValue = _e.setValue, formState = _e.formState, errors = _e.formState.errors;
    // Check if there are any errors in the form
    var hasErrors = Object.keys(errors).length > 0;
    var onSubmit = function (data) {
        var formattedData = {
            user_name: data.name,
            user_email: data.email,
            user_message: data.text,
        };
        emailjs
            .send(EmailConfig.emailServiceId, EmailConfig.emailTemplateId, formattedData, EmailConfig.publicKey)
            .then(function (result) {
            updateComponentState({
                formSubmitted: true,
            });
            console.log(result.text);
        }, function (error) {
            console.log(error.text);
            // Set an error on the form to prevent marking it as submitted
            setError('email', {
                type: 'manual',
                message: 'Failed to send email. Please try again later.',
            });
        });
    };
    return (_jsx(Container, { maxWidth: 'xl', style: { paddingLeft: 0 }, children: _jsx("form", { noValidate: true, onSubmit: handleSubmit(onSubmit), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { size: { xs: 12, lg: 12 }, children: _jsx(TextField, { required: true, id: 'name', disabled: false, placeholder: 'Name *', size: 'small', fullWidth: true, variant: 'outlined', 
                            // slotProps={{
                            //   inputLabel: { shrink: false },
                            // }}
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '4px',
                            }, error: errors.name ? true : false, helperText: (errors === null || errors === void 0 ? void 0 : errors.name) && (_jsx(React.Fragment, { children: (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message })) }) }), _jsx(Grid, { size: { xs: 12, lg: 12 }, children: _jsx(TextField, __assign({ required: true, id: 'email', disabled: false, placeholder: 'Email *', size: 'small', fullWidth: true, variant: 'outlined', slotProps: {
                                inputLabel: { shrink: false },
                            }, sx: {
                                backgroundColor: 'white',
                                borderRadius: '4px',
                            } }, register('email', {
                            required: {
                                value: true,
                                message: 'Email is required',
                            },
                            pattern: {
                                value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                                message: 'This is not a valide email address',
                            },
                        }), { error: errors.email ? true : false, helperText: (errors === null || errors === void 0 ? void 0 : errors.email) && (_jsx(React.Fragment, { children: (_b = errors === null || errors === void 0 ? void 0 : errors.email) === null || _b === void 0 ? void 0 : _b.message })) })) }), _jsx(Grid, { size: { xs: 12, lg: 12 }, children: _jsx(TextField, __assign({ required: true, id: 'text', disabled: false, placeholder: 'Message *', size: 'small', fullWidth: true, variant: 'outlined', multiline: true, slotProps: {
                                inputLabel: { shrink: false },
                            }, sx: {
                                backgroundColor: 'white',
                                borderRadius: '4px',
                            }, rows: 10 }, register('text', {
                            required: {
                                value: true,
                                message: 'Message is required',
                            },
                            maxLength: {
                                value: 1000,
                                message: 'Message cannot exceed 1000 characters',
                            },
                        }), { error: errors.text ? true : false, helperText: (errors === null || errors === void 0 ? void 0 : errors.text) && (_jsx(React.Fragment, { children: (_c = errors === null || errors === void 0 ? void 0 : errors.text) === null || _c === void 0 ? void 0 : _c.message })) })) }), _jsx(Grid, { size: { xs: 12, lg: 12 }, style: {
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }, children: _jsx(Button, { type: 'submit', variant: 'contained', color: 'primary', disabled: componentState.formSubmitted == true || hasErrors, sx: {
                                width: 130,
                                backgroundColor: '#ffffff', // Light background color
                                color: '#000000', // Dark text color
                                '&:hover': {
                                    backgroundColor: '#f0f0f0', // Slightly darker on hover
                                },
                                '&:active': {
                                    backgroundColor: '#e0e0e0', // Even darker on active
                                },
                            }, children: "Submit" }) })] }) }) }));
};
export default ContactForm;
