var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import GlobalStyles from '@mui/material/GlobalStyles';
import theme from './DefaultTheme';
export var getPageSectionStyle = function (isMobile, styles) {
    var defaultStyles = {
        width: '100%',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'transparent',
        padding: isMobile ? 15 : 40,
        margin: 0,
    };
    if (styles) {
        defaultStyles = __assign(__assign({}, defaultStyles), styles);
    }
    return defaultStyles;
};
var globalStyles = (_jsx(GlobalStyles, { styles: {
        '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
        },
        body: {
            color: '#333',
            fontFamily: 'Roboto, sans-serif',
            background: theme.palette.common.white,
        },
        html: {
            'scroll-behavior': 'smooth',
        },
    } }));
export default globalStyles;
