import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import PageSection from '../components/sections/PageSection';
import HeaderSection from '../components/sections/HeaderSection';
import ContactSection from '../components/sections/ContactSection';
var pageContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: 0,
};
var Home = function () {
    return (_jsxs(Container, { style: pageContainerStyle, maxWidth: false, children: [_jsx(PageSection, { sectionId: 'home', children: _jsx(HeaderSection, {}) }), _jsx(PageSection, { sectionId: 'contact', children: _jsx(ContactSection, {}) })] }));
};
export default Home;
