import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import theme from '../Theming/DefaultTheme';
import AppButton from './buttons/AppButton';
var buttonStyle = {
    textTransform: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.secondary.main,
};
var AppNavigation = function () {
    var navigate = useNavigate();
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useState(false), drawerOpen = _a[0], setDrawerOpen = _a[1];
    var handleNavigate = function (path, hash) {
        if (path.startsWith('http')) {
            window.location.href = path;
        }
        else {
            navigate(path);
            if (hash) {
                window.location.hash = hash;
            }
        }
        setDrawerOpen(false); // Close the drawer after navigation
    };
    var toggleDrawer = function (open) { return function () {
        setDrawerOpen(open);
    }; };
    var drawer = (_jsx(Box, { sx: {
            width: 250,
            backgroundColor: 'white',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }, role: 'presentation', onClick: toggleDrawer(false), onKeyDown: toggleDrawer(false), children: _jsx(List, { children: _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', 'contact'); }, children: _jsx(ListItemText, { primary: 'Get In Touch' }) }) }) }));
    return (_jsx(AppBar, { position: 'fixed', sx: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
        }, children: _jsx(Toolbar, { children: isMobile ? (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: 'inherit', edge: 'start', onClick: toggleDrawer(true), sx: { mr: 2, color: theme.palette.primary.main }, children: _jsx(MenuIcon, {}) }), _jsx(Drawer, { anchor: 'left', open: drawerOpen, onClose: toggleDrawer(false), children: drawer })] })) : (_jsx(Box, { style: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                }, children: _jsx(AppButton, { buttonText: 'Get In Touch', mainColor: 'primary', textColor: theme.palette.common.white, styles: { borderRadius: 20 }, onClick: function () { return handleNavigate('/', 'contact'); } }) })) }) }));
};
export default AppNavigation;
