var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography, useMediaQuery, useTheme, } from '@mui/material';
import ContactForm from '../forms/ContactForm';
import theme from '../../Theming/DefaultTheme';
import Grid from '@mui/material/Grid2';
var contactSectionStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 20px',
    backgroundColor: theme.palette.primary.extraDark,
};
var dividerStyle = {
    width: 100,
    margin: '0px 0px 20px 0px',
    borderColor: 'white',
};
var ContactSection = function (_a) {
    var style = _a.style;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var contactFormContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: isMobile ? '100%' : '50%',
    };
    return (_jsx(Box, { style: __assign(__assign({}, contactSectionStyle), style), children: _jsxs(Box, { style: contactFormContainerStyle, children: [_jsx(Typography, { variant: 'h3', component: 'h2', color: 'white', gutterBottom: true, children: "Contact Us" }), _jsx(Divider, { style: dividerStyle }), _jsxs(Grid, { container: true, spacing: 3, width: '100%', justifyContent: 'space-between', children: [_jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsxs(Typography, { variant: 'body1', style: { marginBottom: 20, color: theme.palette.common.white }, children: ["Codefront Technologies is a software solutions company specializing in crafting high quality and innovative solutions. With a focus on creativity and future-ready designs, we empower businesses to navigate the digital landscape with confidence and stay ahead in a rapidly evolving world.", _jsx("br", {}), _jsx("br", {}), "At Codefront Technologies, we\u2019re always excited to collaborate on innovative ideas and cutting-edge solutions. Whether you\u2019re looking to transform your business, have questions about our services, or just want to explore how technology can unlock new possibilities, we\u2019re here to help."] }) }), _jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsx(ContactForm, {}) })] })] }) }));
};
export default ContactSection;
