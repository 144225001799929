import { createTheme } from '@mui/material/styles';
//https://www.w3schools.com/colors/colors_picker.asp
var theme = createTheme({
    palette: {
        primary: {
            extraLight: '#CEE5FD',
            light: '#3B96F7',
            main: '#1A85F8',
            dark: '#0663C6',
            extraDark: '#021931',
        },
        secondary: {
            extraLight: '#99ffeb',
            light: '#00cca3',
            main: '#00A889',
            dark: '#006653',
            extraDark: '#001a15',
        },
        tertiary: {
            extraLight: '#b6b1e7',
            light: '#6d64ce',
            main: '#483dc2',
            dark: '#332b88',
            extraDark: '#070613',
        },
        warning: {
            extraLight: '#FEEACD',
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            extraDark: '#A85500',
        },
        info: {
            extraLight: '#D4EAFC',
            light: '#64b5f6',
            main: '#2B5186',
            dark: '#1976d2',
            extraDark: '#035291',
        },
        success: {
            extraLight: '#CBE7CC',
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            extraDark: '#245626',
        },
        text: {
            primary: '#2B2B2B', // Mine Shaft Grey
            secondary: '#757575',
            disabled: '#bdbdbd',
        },
        grey: {
            50: '#FAFAFA',
            100: '#F5F5F5',
            200: '#EEEEEE',
            300: '#E0E0E0',
            400: '#BDBDBD',
            500: '#9E9E9E',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
        },
    },
    typography: {
        h1: {
            fontSize: '2.225rem',
            '@media (max-width:600px)': {
                fontSize: '1.75rem',
            },
        },
        h2: {
            fontSize: '1.85rem',
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h3: {
            fontSize: '1.6rem',
            '@media (max-width:600px)': {
                fontSize: '1.25rem',
            },
        },
        h4: {
            fontSize: '1.35rem',
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        h5: {
            fontSize: '1.1rem',
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        h6: {
            fontSize: '0.975rem',
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        body1: {
            fontSize: '1.1rem',
            fontWeight: 400,
            lineHeight: 1.23, // had to change back
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        body2: {
            fontSize: '0.975rem',
            fontWeight: 400,
            lineHeight: 1.57,
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        subtitle1: {
            fontSize: '1.1rem',
            fontWeight: 400,
            lineHeight: 1.75,
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        subtitle2: {
            fontSize: '0.975rem',
            fontWeight: 500,
            lineHeight: 1.57,
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        caption: {
            fontSize: '0.85rem',
            fontWeight: 400,
            lineHeight: 1.66,
            '@media (max-width:600px)': {
                fontSize: '0.625rem',
            },
        },
        overline: {
            fontSize: '0.85rem',
            fontWeight: 400,
            lineHeight: 2.66,
            '@media (max-width:600px)': {
                fontSize: '0.625rem',
            },
        },
        button: {
            fontSize: '0.975rem',
            fontWeight: 500,
            lineHeight: 1.75,
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});
export default theme;
