var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var headerSectionStyles = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url("https://strsharedeus.blob.core.windows.net/apps/codefront/images/background1.png")',
    backgroundSize: 'cover',
    backgroundPosition: '0 0',
    width: '100%',
    height: '90vh',
    padding: '30px 16px 10px 16px',
};
var headerSectionContent = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: '100%',
};
var HeaderSection = function () {
    var theme = useTheme();
    var navigate = useNavigate();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var handleNavigate = function (path, hash) {
        if (path.startsWith('http')) {
            window.location.href = path;
        }
        else {
            navigate(path);
            if (hash) {
                window.location.hash = hash;
            }
        }
    };
    var headerContainerStyles = function () {
        var styles = __assign({}, headerSectionStyles);
        if (isMobile) {
            styles = __assign(__assign({}, styles), { height: 'auto', paddingTop: 2 });
        }
        return styles;
    };
    return (_jsx(Box, { style: headerContainerStyles(), children: _jsx(Box, { style: headerSectionContent, children: _jsx("img", { alt: 'logo', style: { width: isMobile ? 200 : 600 }, src: 'https://strsharedeus.blob.core.windows.net/apps/codefront/images/full-logo.png' }) }) }));
};
export default HeaderSection;
